import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Webdev() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();

  const courses = [
    {
      title: 'FullStack MERN Course',
      image: 'https://www.codexfuture.in/assets/img/courses/1.png',
      description: 'An extensive industry-relevant FullStack MERN course with Placement Assistance!',
      link: '/Fullstack',
    },
    {
      title: 'Frontend ReactJS Course',
      image: 'https://www.codexfuture.in/assets/img/courses/5.png',
      description: 'An extensive industry-relevant Frontend ReactJS course with Placement Assistance!',
      link: '/Frontend',
    },
    {
      title: 'Mobile App Development',
      image: 'https://www.codexfuture.in/assets/img/courses/6.png',
      description: 'An extensive industry-relevant Mobile App Development course with Placement Assistance!',
      link: '/Mobileappdev',
    },
    {
      title: 'Python Django Course',
      image: 'https://www.codexfuture.in/assets/img/courses/3.png',
      description: 'An extensive industry-relevant Python Django course with Placement Assistance!',
      link: '/python',
    },
    {
      title: 'Next JS',
      image: 'https://miro.medium.com/v2/resize:fit:1000/1*v3XndYeIsBtk4CkpMf7vmA.jpeg',
      description: 'An extensive industry-relevant Next.js course with Placement Assistance!',
      link: '/Nextjs',
    },
    {
      title: 'Data Analytics Course',
      image: 'https://www.codexfuture.in/assets/img/courses/4.png',
      description: 'An extensive industry-relevant Data Analytics course with Placement Assistance!',
      link: '/Dataanalytics',
    },
  ];

  return (
    <div className='flex flex-col gap-8 py-[30px]'>
      <h1 className='text-[40px] font-bold text-center'>
        Our Most Popular <span className='text-[#1B8DD8]'>Courses</span>
      </h1>
      <label className='text-center text-[20px] font-semibold'>
        These are the top short term job-oriented Courses & certification courses which are In high Demand in 2024
      </label>
      <div className='lg:flex lg:flex-wrap gap-y-2 hidden w-[100%] gap-[3.5%]'>
        {courses.map((course, index) => (
          <div
            key={index}
            onClick={() => navigate(course.link)}
            className=' lg:w-[31%] w-full cursor-pointer border'
            data-aos='fade-up'
          >
            <div>
              <img
                className='lg:h-[30vh] h-[26vh] w-full rounded-md'
                src={course.image}
                alt={course.title}
              />
            </div>
            <div className='flex flex-col gap-[10px] pl-[15px] py-[15px]'>
              <label className='text-[22px] font-bold font-sans'>
                {course.title}
              </label>
              <div className='flex flex-row gap-2'>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
              </div>
              <label className='text-[gray] text-[16px]'>{course.description}</label>
              <hr className='mt-[4%]' />
              <div className='flex flex-row gap-3'>
                <i className='fa fa-bullhorn text-[28px]' aria-hidden='true'></i>
                <label className='text-[19px] font-sans text-[#1B8DD8]'>
                  Get Job Assurity Within 10 Guaranteed Interviews
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Mobile Version */}
      <div className='lg:hidden flex flex-col w-[100%] gap-[3.5%]'>
        {courses.map((course, index) => (
          <div
            key={index}
            onClick={() => navigate(course.link)}
            className='h-[60vh] w-full cursor-pointer'
            data-aos='fade-up'
          >
            <div>
              <img
                className='lg:h-[30vh] h-[26vh] w-full rounded-md'
                src={course.image}
                alt={course.title}
              />
            </div>
            <div className='flex flex-col gap-[10px] pl-[15px] py-[15px]'>
              <label className='text-[20px] font-bold font-sans'>
                {course.title}
              </label>
              <div className='flex flex-row gap-2'>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
                <i className='fa fa-star text-[#1B8DD8] text-[20px]' aria-hidden='true'></i>
              </div>
              <label className='text-[gray] text-[16px]'>{course.description}</label>
              <hr className='mt-[4%]' />
              <div className='flex flex-row gap-3'>
                <i className='fa fa-bullhorn text-[28px]' aria-hidden='true'></i>
                <label className='text-[19px] font-sans text-[#1B8DD8]'>
                  Get Job Assurity Within 10 Guaranteed Interviews
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Webdev;
