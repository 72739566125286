import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import image from '../images/Screenshot 2024-05-08 161913.png'

function Reactapp() {
  return (
    <div className=' flex flex-col w-full justify-center items-center'>
    <Header/>
    <div className='py-20 w-[90%] flex lg:flex-row flex-col gap-4 bg-[]'>
    <div className='lg:w-[50%] w-full flex lg:hidden'>
    <img src={image}></img>
    </div>
    <div className='flex flex-col gap-4 lg:w-[50%] w-full'>
    <h1 className='lg:text-[30px] text-[24px] font-semibold'>React JS Course Overview
    </h1>
    <label className='text-[17px] leading-loose'>React is a framework that employs Webpack to automatically compile React, JSX, and ES6 code while handling CSS file prefixes. React is a JavaScript-based UI development library. Although React is a library rather than a language, it is widely used in web development. The library first appeared in May 2013 and is now one of the most commonly used frontend libraries for web development.React offers various extensions for entire application architectural support, such as Flux and React Native, beyond mere UI.

    </label>
    <h1 className='text-[26px] font-semibold'>Skills Covered</h1>
    <div className='flex lg:flex-row flex-col lg:gap-8 gap-0  lg:w-[90%] w-full bg-[]'>
    <div className='flex flex-col gap-4 w-[]'>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-2 text-[#0096EB]" aria-hidden="true"></i>
    <label className='text-[17px] leading-loose'>
    Learn to create basic web pages using HTML5</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-2 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px] leading-loose'>
     Learn the Javascript language from basic to advanced along with the Next Gen JS - ES6</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Create user-friendly, beautiful, light and fast websites using React JS</label></div>
    <div className='flex flex-row gap-2 '>
    <i class="fa fa-long-arrow-right relative top-2 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px] leading-loose'>
     Learn to style and add animations to bring those basic web pages to life using CSS3</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-2 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px] leading-loose'>
     Learn to create responsive and interactive web pages using Javascript and jQuery</label></div>
    </div>
    <div className='flex flex-col gap-4'>
    <div className='flex flex-row gap-3 mt-4 '>
    <i class="fa fa-long-arrow-right relative top-2 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px] leading-loose'>
     Become an expert front end developer using ReactJs</label></div>
    <div className='flex flex-row gap-3'>
    <i class="fa fa-long-arrow-right relative top-2 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px] leading-loose'>
    Understand the Nodejs framework</label></div>
    <div className='flex flex-row gap-3'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Master Expressjs</label></div>
    </div>
    </div>
    
    </div>
    <div className='w-[50%] lg:flex flex-col hidden'>
    <div className='w-[100%]'>
    <img className=' w-full  lg:h-[80vh] bg-cover' src={image}></img></div>
    </div>
  
    </div>
    
    <Footer/>
    </div>
  )
}

export default Reactapp