import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'


function Node() {
  return (

    <div className=' flex flex-col w-full justify-center items-center'>
    <Header/>
    <div className='py-20 w-[90%] flex lg:flex-row flex-col gap-4 bg-[]'>
    <div className='lg:w-[50%] w-full flex lg:hidden'>
    <img src='https://miro.medium.com/v2/resize:fit:1400/0*7oDBZmPOxKGhHG1t'></img>
    </div>
    <div className='flex flex-col gap-4 lg:w-[50%] w-full'>
    <h1 className='lg:text-[30px] text-[24px] w-full  font-semibold'>Node JS Course Overview
    </h1>
    <label className='text-[17px] leading-loose'>The Node JS certification training course helps you gain an in-depth knowledge of concepts such as Express.js, Node Packet Manager (NPM), shrink-wrap, NPM Vet, REST, Express.js with MongoDB, and SQLite CRUD operations. This Node JS training focuses on the essential concepts of Node JS and provides hands-on experience in building an HTTP server.</label>
    <h1 className='lg:text-[28px] text-[26px] font-semibold'>Skills Covered</h1>
    <div className='flex lg:flex-row flex-col lg:w-[90%] w-full justify-between'>
    <div className='flex flex-col gap-4'>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB]" aria-hidden="true"></i>
    <label className='text-[17px]'>
    Understand the Nodejs framework</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Multiprocessing in Nodejs</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     HTTP server with Nodejs</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Application using Socketio</label></div>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Work with Node projects</label></div>
    </div>
    <div className='flex flex-col gap-4 lg:mt-0 mt-4'>
    <div className='flex flex-row gap-2'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Work with shrinkwrap</label></div>
    <div className='flex flex-row gap-3'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
    Understand the Nodejs framework</label></div>
    <div className='flex flex-row gap-3'>
    <i class="fa fa-long-arrow-right relative top-1 text-[#0096EB] " aria-hidden="true"></i>
     <label className='text-[17px]'>
     Master Expressjs</label></div>
    </div>
    </div>
    <h1 className='font-semibold text-[26px]'>Benefits</h1>
    <label>A Node.Js certification can help you build powerful and complex applications without the need of complex codes. Also, a certified Node.Js developer earns an average of $104,964 per annum.

    </label>
    </div>
    <div className='w-[50%] lg:flex hidden'>
    <img src='https://miro.medium.com/v2/resize:fit:1400/0*7oDBZmPOxKGhHG1t'></img>
    </div>
  
    </div>
    
    <Footer/>
    </div>
  )
}

export default Node