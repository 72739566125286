import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useState } from 'react';
function Fullstack() {
  const [activeTab, setActiveTab] = useState("overview");

    const handleTabClick = (activeTab) => {
      setActiveTab(activeTab);
    };
  return (
    <div>
    <Header/>
    <div className='w-[100%] flex lg:flex-row flex-col justify-center items-center lg:mt-20 py-[30px]'>
    <div className='w-[90%] lg:gap-[4%] gap-[30px] flex lg:flex-row flex-col'>
      <div className='lg:w-[80%] w-full gap-[12px] flex flex-col'>
      <img className='lg:h-[50vh] h-auto w-full bg-cover' src='https://www.codexfuture.in/assets/img/courses/1.png'></img>
      <div className=' w-full py-[4%] px-[4%] rounded-md bg-[#323B8D] flex justify-around gap-[10px]'>
      <button onClick={()=>handleTabClick('overview')} className='h-[5vh] w-[40%] bg-[#00E1FE] flex justify-center items-center text-[19px] font-semibold rounded-md text-[black]'>
      Overview
      </button>
      <button className=' w-[60%] font-semibold lg:text-[17px] text-[15px]' onClick={() => handleTabClick('courseContent')}>COURSE CONTENT</button>
      </div>
      {activeTab === 'overview' && <div> <label className='text-[26px] font-semibold'>Description</label>
        <p className='w-full lg:leading-10 leading-8 lg:text-[22px] text-[20px] font-serif text-justify'>A MERN stack full developer is a software developer who specializes in building web applications using the MERN stack. The MERN stack is a collection of technologies that are commonly used together to create dynamic web applications. Additionally, CodeBetter IT Institute offers exceptional best data science and machine learning course for aspiring developers.</p>
        <div className='flex flex-col gap-[10px]'>
        <label className='lg:text-[26px] text-[20px] font-semibold'>Course Content Overview:</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-    HTML CSS BootStrap</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   JavaScript and JQuery</label>
        <label className='lg:text-[22px] text-[20px] w-full font-sans'>-   React.js</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   Node.js</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   RDBMS with MySQL</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   MongoDB</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   Express.js</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   GitHub</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   Major and Live Projects</label>
        
        </div></div>  }
        {activeTab === 'courseContent' && (
          <div className="course-content lg:px-6 px-3">
            <h1 className='text-[32px] font-semibold text-center'>FullStack MERN Course</h1>
            <ul className='flex flex-col gap-[10px] justify-center'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>HTML</li></div>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)   -   HTML Introduction, Basic Tags</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)   -   Images and HyperLinks, Paragraph</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)   -   Division, Ordered and Unordered List</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -   Table</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)  -   Form , Submit and Reset Button, Text, Password, Radio, Check Box</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)   -   UI Elements : DropDown , Calander etc.</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Audio, Video</li>
              
            </ul>
            <ul className='flex flex-col gap-[10px]'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>CSS</li></div>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)    -   CSS Introduction, Inline , Internal and External CSS</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)     -   CSS Properties and Selectors</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)    -   Box Model</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -   Display Properties , Floating</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)   -   Navigation Bar</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)     -   Website Template Design</li>
          </ul>
            <ul className='flex flex-col gap-[10px]'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>Bootstrap</li></div>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)    -   Bootstrap Introduction</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)    -   Bootstrap Grid System and Classes</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)      -   Inputs , Buttons ,Tables , Forms</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)   -   Alert , Color Classes, Popup Model</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)  -   Form , Submit and Reset Button, Text, Password, Radio, Check Box</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)    -   Bootstrap Template Downloading and Customizing</li>
            
          </ul>
          <ul className='flex flex-col gap-[10px]'>
          <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>JavaScript</li></div>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)     -   JavaScript Introduction</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)     -   variables and operators</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)     -   If and Switch Statement</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)   -   Looping Statement</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)    -   Functions , Default Arguments, recursion, strict mode</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)    -   Events(click, dobleclick, keypress,mouseover etc.</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)   -   setInterval() , setTimeOut(), clearInterval() , clearTimeOut()</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)     -   Array and Array functions</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)      -   String and String Functions , Date and Format</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.) -   Javascript Object , Array of Objects, JSON</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)   -   Local Storage</li>
        </ul>
        <ul className='flex flex-col gap-[10px]'>
        <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
        <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
          <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>Javascript Advance</li></div>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)   -   Javascript Object - Property , Methods, Prototype , Accessors</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)  -   Class and Object,Constructor</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)    -   Inheritance, Overriding</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -   Annonymous Function and Arrow Function</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)    -   Async , Await, Promise, Callback</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)    -   HOF in Array : filter() , map() , reduce() , some() , every() , sort() , find()</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)   -   Closure and Curring</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)    -   Spread and Rest Operator</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Binding with function : bind() , call() , apply()</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)    -   Generators</li>
        
      </ul>
      <ul className='flex flex-col gap-[10px]'>
      <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
      <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
        <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>ReactJS</li></div>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-  React Introduction and Architecture</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-   Create React App and Run App)
      </li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - React Components (Function and Class Component)</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - React Events Handling.</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   React State</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   React Props , TypeChecking with PropType</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Communication Between Parent and Child Component</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - React Lifecycle(Mounting)</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - React Forms(Controlled and UnControlled Component), React Bootstrap</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  React Routing, React Higher Order Component</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - HTTP Server Communication (Fetch API)</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - React Hook</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  React Redux</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Redux Thunk, Redux Saga</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  React LocalStorage</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   React Axios and JSON Parsing, REST API</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Firebase Phone Authentication</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - File Uploading</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - File Uploading</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - JWT Token</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -Payment Integration - Stripe/RazorPay</li>
    </ul>
    <ul className='flex flex-col gap-[10px]'>
    <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
    <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
      <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>NodeJS</li></div>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - NodeJS Introduction , Architecture , Installation and Setup</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - REPL Terminal
      </li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Immutable and Immer Module</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Globals Object</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Path , Url , FS , OS Node Modules</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Custom Module , Importing Module</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -NPM and package.json Introduction</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - Exceptional Handling</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Web Application Introduction , Client-Server Architecture</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Http Module</li>
    </ul>
    <ul className='flex flex-col gap-[10px]'>
    <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
    <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
      <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>RDBMS with MYSQL</li></div>
    <li className=' leading-8 text-[white] text-[20px] font-sans '>-  RDBMS Introduction</li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '>-   SQL and Statement Types
    </li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '> - Create Database and Tables</li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '> - Create and Alter Table with all constraints</li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Select , Insert , Update and Delete</li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '> -  OrderBy , GroupBy</li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '> - Normalization , Primary and Foreign Key</li>
    <li className=' leading-8 text-[white] text-[20px] font-sans '>  - Joins and Subquery</li>
  </ul>
  
    <ul className='flex flex-col gap-[10px]'>
    <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
    <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
      <li className=' leading-8 text-[black] text-[20px] font-sans font-bold '>MongoDB Database Development</li></div>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-  MongoDB Introduction</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-   Database , Collection and Document
      </li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Create Database and Collection</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Fetch Data from Collection</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Projection , Sorting , Limiting</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Insert , Update and Delete</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Drop Collection and Database</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - Backup</li>
    </ul>
    <ul className='flex flex-col gap-[10px]'>
    <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
    <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
      <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>ExpressJS</li></div>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-  Express Introduction and Architecture</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-   MVC Introduction
      </li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Express Middleware - Inbuilt</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Express URL Mapping and Routing</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   Template Engines Introduction</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  EJS and Handlebars Introduction</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Express Model and Working</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - Mysql Connectivity (CRUD) with Express</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - MongoDB Connectivity (CRUD) with (mongodb module)</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Mongoose Module in Express</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Response Headers</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Custom Middleware</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Static Files, CORS</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  File Uploading and Email Sending</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  GET, POST, PUT , DELETE Methods : JSON REST API</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   JWT Authentication</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Swagger - API Management</li>
    </ul>
          </div>

          
          
          
          
        )}
     
      </div>

      <div className='  lg:w-[50%] w-full items-center lg:h-[65vh] h-auto flex flex-col border lg:gap-[2.5%] gap-[8px]'>
      <h1 className='text-[black] font-semibold lg:text-[35px] m-0 text-center'>Course <span className=' text-center text-[#323B8D] lg:text-[35px] font-semibold'>Features</span></h1>
      <div className='w-[100%] flex pt-4'>
      <div className='w-[50%] flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Group%20878.png'></img>
      <label className='lg:text-[20px] font-semibold'>Duration</label>
      </div>
      <div className='  w-[50%] flex justify-end  font-semibold lg:text-[20px] pr-[6%]'>1 to 2 months</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%]  flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-language.png'></img>
      <label className='lg:text-[20px] font-semibold'>Language</label>
      </div>
      <div className='  w-[40%]  flex justify-end pr-[6%] font-semibold lg:text-[20px]'>Hindi/English</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex'>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-certificate.png'></img>
      <label className='lg:text-[20px] font-semibold'>	Certificate</label>
      </div>
      <div className=' pr-[6%] flex w-[40%] justify-end items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 '>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-md-people.png'></img>
      <label className='lg:text-[20px] font-semibold'>Expert trainers</label>
      </div>
      <div className=' flex pr-[6%] w-[40%] justify-end items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[90%] pl-[6%]  flex  gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-ios-time.png'></img>
      <label className='lg:text-[20px] font-semibold'>Flexible Timing</label>
      </div>
      <div className=' flex pr-[6%] w-[50%] justify-end  bg-[] items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 bg-[]'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20payment-cash.png'></img>
      <label className='lg:text-[20px] font-semibold'>	Fee Installements	</label>
      </div>
      <div className=' pr-[6%] flex justify-end w-[40%]  items-center font-semibold lg:text-[20px] '>Yes</div>
      
      </div>
      <hr></hr>
     
       <button className='w-[50%] rounded-md py-2 px-2 bg-[#00E1FE] lg:text-[22px] text-[black] font-semibold mt-[5%]'>Reach Us</button>
      </div>

    
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Fullstack