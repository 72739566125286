import React from 'react'

function Beliefs() {
  return (
    <div className='pt-24 w-[100%] flex flex-row justify-center'>
    <div className='w-[90%] flex lg:flex-row lg:items-center flex-col gap-10'>
    <div className='lg:w-[50%] w-full'>
    <img className='w-full lg:h-[67vh] h-[30vh]' src='https://www.ittrainingindore.in/wp-content/uploads/2023/03/best-educators-image.svg'></img>
    </div>
    <div className='lg:w-[50%] w-full flex flex-col gap-4'>
    <h1 className='font-bold lg:text-[40px] text-[28px]'>Our Beliefs
    </h1>
         <label className=' text-[17px]'>
         <span className='font-semibold'>Coding Shaala</span> Indore believe in offering job-oriented software training programs which are well-planned, keeping in view industry requirements and future prospects. Although we have the latest available resources to cater, giving the most outstanding results. We believe that it is not just money or manpower but, rather, team efforts which can make any organisation reach the sky-high.</label>
       <label className=' text-[17px]'>We have top-level faculty and a dedicated <span className='font-semibold'>placement cell</span> because for all the tech and non tech students, they are the building block on which the organisation rests. We give our students the freedom to discuss, contradict and learn. Also ensure that the right student chooses the right course according to his/her academic background, aptitude and skill-set. This will help our students get the career edge and the extra push that is so highly needed in a competitive job market, eventually leading to professional success.</label>
       
    </div>
    
    </div>
    </div>
  )
}

export default Beliefs