import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Benefits() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms for the animations
  }, []);

  return (
    <div className='flex flex-col items-center gap-4 my-20'>
      <div 
        className='h-[4vh] lg:w-[15%] w-[50%] bg-[#EFEEFE] rounded-xl flex justify-center items-center'
        data-aos="fade-up" // Add AOS animation
      >
        <label className='text-[19px] text-[#5751E1] font-semibold'>Our Top Features</label>
      </div>
      <h1 
        className='lg:text-[37px] text-[33px] text-center font-sans font-semibold'
        data-aos="fade-up" // Add AOS animation
      >
        Achieve Your Goal With Coding Shaala
      </h1>
      <label 
        className='text-[17px] text-[gray] font-sans text-center'
        data-aos="fade-up" // Add AOS animation
      >
        Discover career paths that truly resonate with your passion & explore mentorship programs that align perfectly with you.
      </label>
      <div className='flex lg:flex-row flex-col w-full lg:gap-[25px] gap-[30px] mt-[2%]'>
        <div 
          className='flex flex-col lg:w-[33%] w-full gap-[15px] px-[3%] border-1 bg-[#F1FDFF] py-[3%] shadow-xl hover:shadow-none duration-200 rounded-2xl'
          data-aos="fade-up" // Add AOS animation
        >
          <div className='flex flex-row gap-3'>
            <div className='flex justify-center items-center h-[60px] w-[60px] rounded-full bg-[#1BCBE3]'>
              <i className="text-[30px] text-[white] fa fa-graduation-cap" aria-hidden="true"></i>
            </div>
            <label className='text-[20px] font-semibold relative top-4'>Expert Tutors</label>
          </div>
          <label className='text-[19px] text-[orange] font-sans'>
            All our trainers are working professionals with more than 6 years of experience in the industry.
          </label>
        </div>
        <div 
          className='flex flex-col lg:w-[33%] w-full gap-[15px] px-[3%] border-1 bg-[#EDEAFF] py-[3%] shadow-xl hover:shadow-none duration-200 rounded-2xl'
          data-aos="fade-up" // Add AOS animation
        >
          <div className='flex flex-row gap-3'>
            <div className='flex justify-center items-center h-[60px] w-[60px] rounded-full bg-[#5751E1]'>
              <i className="text-[30px] text-[white] fa fa-graduation-cap" aria-hidden="true"></i>
            </div>
            <label className='text-[20px] font-semibold relative top-4'>Effective Courses</label>
          </div>
          <label className='text-[19px] text-[#FFC224] font-sans'>
            Whether you are a fresher or an experienced professional, we always provide 100% placement assistance.
          </label>
        </div>
        <div 
          className='flex flex-col lg:w-[33%] w-full gap-[15px] px-[3%] border-1 bg-[#FFF7E2] py-[3%] shadow-xl hover:shadow-none duration-200 rounded-2xl'
          data-aos="fade-up" // Add AOS animation
        >
          <div className='flex flex-row gap-3'>
            <div className='flex justify-center items-center h-[60px] w-[60px] rounded-full bg-[#FFC224]'>
              <i className="text-[30px] text-[white] fa fa-graduation-cap" aria-hidden="true"></i>
            </div>
            <label className='text-[20px] font-semibold relative top-4'>Capstone Projects</label>
          </div>
          <label className='text-[19px] text-[orange] font-sans'>
            Job-oriented training courses help students to start their career after their graduation quickly.
          </label>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
