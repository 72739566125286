import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useState } from 'react';
function Mobileappdev() {
  const [activeTab, setActiveTab] = useState("overview");

    const handleTabClick = (activeTab) => {
      setActiveTab(activeTab);
    };
  return (
    <div>
    <Header/>
    <div className='w-[100%] flex lg:flex-row flex-col justify-center items-center py-[30px]'>
    <div className='w-[90%] lg:gap-[4%] gap-[30px] flex lg:flex-row flex-col'>
      <div className='lg:w-[80%] w-full gap-[12px] flex flex-col'>
      <img className='lg:h-[50vh] h-auto w-full bg-cover' src='https://www.codexfuture.in/assets/img/courses/6.png'></img>
      <div className=' w-full py-[4%] px-[4%] rounded-md bg-[#323B8D] flex justify-around gap-[10px]'>
      <button onClick={()=>handleTabClick('overview')} className='h-[5vh] w-[40%] bg-[#00E1FE] flex justify-center items-center text-[19px] font-semibold rounded-md text-[black]'>
      Overview
      </button>
      <button className=' w-[60%] font-semibold lg:text-[17px] text-[15px]' onClick={() => handleTabClick('courseContent')}>COURSE CONTENT</button>
      </div>
      {activeTab === 'overview' && <div> <label className='text-[26px] font-semibold'>Description</label>
        <p className='w-full lg:leading-10 leading-8 lg:text-[22px] text-[20px] font-serif text-justify'>React Native (also known as RN) is a popular JavaScript-based mobile app framework that allows you to build natively-rendered mobile apps for iOS and Android . The framework lets you create an application for various platforms by using the same codebase .</p>
        <div className='flex flex-col gap-[10px]'>
        <label className='lg:text-[26px] text-[20px] font-semibold'>Course Content Overview:</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-    JavaScript (ES6+)</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   React Basics</label>
        <label className='lg:text-[22px] text-[20px] w-full font-sans'>-   Basic HTML/CSS</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   Core React Native Topics:</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-  React Native Basics</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   MongoDB</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   Navigation</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-  State Management</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   APIs and Data Fetching</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>- click on Course Content for more details</label>
        
        </div></div>  }
        {activeTab === 'courseContent' && (
          <div className="course-content lg:px-6 px-3">
            <h1 className='text-[32px] font-semibold text-center'>FullStack MERN Course</h1>
            <ul className='flex flex-col gap-[10px] justify-center'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>HTML</li></div>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)   -   HTML Introduction, Basic Tags</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)   -   Images and HyperLinks, Paragraph</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)   -   Division, Ordered and Unordered List</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -   Table</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)  -   Form , Submit and Reset Button, Text, Password, Radio, Check Box</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)   -   UI Elements : DropDown , Calander etc.</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Audio, Video</li>
              
            </ul>
            <ul className='flex flex-col gap-[10px]'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>CSS</li></div>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)    -   CSS Introduction, Inline , Internal and External CSS</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)     -   CSS Properties and Selectors</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)    -   Box Model</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -   Display Properties , Floating</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)   -   Navigation Bar</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)     -   Website Template Design</li>
          </ul>
            <ul className='flex flex-col gap-[10px]'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>Bootstrap</li></div>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)    -   Bootstrap Introduction</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)    -   Bootstrap Grid System and Classes</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)      -   Inputs , Buttons ,Tables , Forms</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)   -   Alert , Color Classes, Popup Model</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)  -   Form , Submit and Reset Button, Text, Password, Radio, Check Box</li>
            <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)    -   Bootstrap Template Downloading and Customizing</li>
            
          </ul>
          <ul className='flex flex-col gap-[10px]'>
          <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>JavaScript</li></div>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)     -   JavaScript Introduction</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)     -   variables and operators</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)     -   If and Switch Statement</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)   -   Looping Statement</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)    -   Functions , Default Arguments, recursion, strict mode</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)    -   Events(click, dobleclick, keypress,mouseover etc.</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)   -   setInterval() , setTimeOut(), clearInterval() , clearTimeOut()</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)     -   Array and Array functions</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)      -   String and String Functions , Date and Format</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.) -   Javascript Object , Array of Objects, JSON</li>
          <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)   -   Local Storage</li>
        </ul>
        <ul className='flex flex-col gap-[10px]'>
        <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
        <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
          <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>Javascript Advance</li></div>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)   -   Javascript Object - Property , Methods, Prototype , Accessors</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)  -   Class and Object,Constructor</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)    -   Inheritance, Overriding</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -   Annonymous Function and Arrow Function</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)    -   Async , Await, Promise, Callback</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)    -   HOF in Array : filter() , map() , reduce() , some() , every() , sort() , find()</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)   -   Closure and Curring</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)    -   Spread and Rest Operator</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Binding with function : bind() , call() , apply()</li>
        <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)    -   Generators</li>
        
      </ul>
      <ul className='flex flex-col gap-[10px]'>
      <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
      <i class="fa fa-pencil relative left-2 text-[red] text-[20px]" aria-hidden="true"></i>
        <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '> Core React Native Topics:</li></div>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '>-  Setting up the development environment</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>-   Core components like View, Text, Image, ScrollView
      </li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Styling in React Native</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Flexbox layout</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   Handling user input with TextInput, Button, Touchable components</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -   Platform-specific code for iOS and Android</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '>  Navigation</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - React Navigation for handling navigation and routing in your app</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '>  - Stack, Tab, and Drawer navigators</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '>   State Management</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Context API</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Redux (optional, but often used for complex state management)</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '>  APIs and Data Fetching</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Fetching data from APIs with fetch or axios</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Handling asynchronous data</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold'>   Handling Forms</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> -  Managing form state</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Validating user input</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '> Native Modules</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Linking native code</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Using third-party libraries and native modules</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '>  Animations</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Basic animations using the Animated API or third-party libraries like Reanimated</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold'>  Debugging and Testing</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Using the React Native Debugger</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Unit testing with Jest</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Testing components with React Native Testing Library</li>
      <li className=' leading-8 text-[white] text-[22px] font-sans font-semibold '>  Deployment</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - Building and deploying apps to the App Store (iOS) and Google Play Store (Android)</li>
      <li className=' leading-8 text-[white] text-[20px] font-sans '> - </li>
    </ul>
  
   
          </div>

          
          
          
          
        )}
     
      </div>

      <div className='  lg:w-[50%] w-full items-center lg:h-[65vh] h-auto flex flex-col border lg:gap-[2.5%] gap-[8px]'>
      <h1 className='text-[black] font-semibold lg:text-[35px] m-0 text-center'>Course <span className=' text-center text-[#323B8D] lg:text-[35px] font-semibold'>Features</span></h1>
      <div className='w-[100%] flex pt-4'>
      <div className='w-[50%] flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Group%20878.png'></img>
      <label className='lg:text-[20px] font-semibold'>Duration</label>
      </div>
      <div className='  w-[50%] flex justify-end  font-semibold lg:text-[20px] pr-[6%]'>1 to 2 months</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%]  flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-language.png'></img>
      <label className='lg:text-[20px] font-semibold'>Language</label>
      </div>
      <div className='  w-[40%]  flex justify-end pr-[6%] font-semibold lg:text-[20px]'>Hindi/English</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex'>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-certificate.png'></img>
      <label className='lg:text-[20px] font-semibold'>	Certificate</label>
      </div>
      <div className=' pr-[6%] flex w-[40%] justify-end items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 '>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-md-people.png'></img>
      <label className='lg:text-[20px] font-semibold'>Expert trainers</label>
      </div>
      <div className=' flex pr-[6%] w-[40%] justify-end items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[90%] pl-[6%]  flex  gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-ios-time.png'></img>
      <label className='lg:text-[20px] font-semibold'>Flexible Timing</label>
      </div>
      <div className=' flex pr-[6%] w-[50%] justify-end  bg-[] items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 bg-[]'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20payment-cash.png'></img>
      <label className='lg:text-[20px] font-semibold'>	Fee Installements	</label>
      </div>
      <div className=' pr-[6%] flex justify-end w-[40%]  items-center font-semibold lg:text-[20px] '>Yes</div>
      
      </div>
      <hr></hr>
     
       <button className='w-[50%] rounded-md py-2 px-2 bg-[#00E1FE] lg:text-[22px] text-[black] font-semibold mt-[5%]'>Reach Us</button>
      </div>

    
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Mobileappdev