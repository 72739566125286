import React from "react";

function Opportunity() {
  return (
    <div className="pt-24 w-[100%] flex flex-row justify-center">
      <div className="w-[90%] flex lg:flex-row flex-col gap-10">
        <div className="lg:w-[50%] w-full">
          <img
            className="w-full lg:h-[67vh] h-[30vh]"
            src="https://images.shiksha.com/mediadata/images/articles/1569215107phpxeX0gs.jpeg"
          ></img>
        </div>
        <div className="lg:w-[50%] w-full flex flex-col gap-2">
          <h1 className="font-bold lg:text-[40px] text-[28px]">
            Taking your imagination as our upcoming opportunity.
          </h1>
          <label className="text-[17px]">
            We are a leading it training institute in Indore, passionately
            delivering immersive digital learning experiences. Through the
            latest courses and technology, industry partnerships, and expert
            faculty, we make top-tier education accessible to everyone. At
            CodingShaala, we offer training in cutting-edge technologies like
            Java, JavaScript, React, Python, and more, designed to equip you
            with the skills needed to excel in the modern tech landscape.
          </label>
          <label className="font-semibold text-[20px] mt-2">
            Courses we offer
          </label>
          <div className="flex flex-row gap-2">
            <i
              class="fa text-[30px] relative top-[-3px] text-[#1AAEF4] fa-long-arrow-right"
              aria-hidden="true"
            ></i>
            <label className="text-[17px]">
              Looking for <span className="font-semibold">Scratch Courses</span>
            </label>
          </div>
          <div className="flex flex-row gap-2">
            <i
              class="fa text-[30px] relative top-[-3px] text-[#1AAEF4] fa-long-arrow-right"
              aria-hidden="true"
            ></i>
            <label className="text-[17px]">
              Looking for{" "}
              <span className="font-semibold">
                {" "}
                Experimentation while Learning
              </span>
            </label>
          </div>
          <div className="flex flex-row gap-2">
            <i
              class="fa text-[30px] relative top-[-3px] text-[#1AAEF4] fa-long-arrow-right"
              aria-hidden="true"
            ></i>
            <label className="text-[17px]">
              Looking for{" "}
              <span className="font-semibold"> Technology Updates</span>
            </label>
          </div>
          <div className="flex flex-row gap-2">
            <i
              class="fa text-[30px] relative top-[-3px] text-[#1AAEF4] fa-long-arrow-right"
              aria-hidden="true"
            ></i>
            <label className="text-[17px]">
              <span className="font-semibold">Pocket-friendly</span> courses
            </label>
          </div>
          <div className="flex flex-row gap-2">
            <i
              class="fa text-[30px] relative top-[-3px] text-[#1AAEF4] fa-long-arrow-right"
              aria-hidden="true"
            ></i>
            <label className="text-[17px]">
              Job Oriented{" "}
              <span className="font-semibold">Skills Upgradation</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Opportunity;
