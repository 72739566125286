import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapComponent = () => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px', // Ensures the map takes the specified height
  };

  const center = {
    lat: 22.7639296,
    lng: 75.8874112,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBEtrzUrFqOFpKgTQJfCZdJUSzJTZV7WBU">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={16}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
