import React, { useState } from 'react';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos'; // Import AOS
import { useEffect } from 'react';

function Feedback() {
  const [move, setMove] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS
  }, []);

  return (
    <div className='w-full flex gap-4 flex-col relative lg:items-start items-center pb-[40px]' data-aos="fade-up">
      <div className='lg:flex hidden flex-row gap-8 absolute right-20 top-[10vh]'>
        <label 
          onClick={() => move > 0 ? setMove(move - 50) : setMove(50)} 
          className='text-[40px] cursor-pointer'
        >
          {"-"}
        </label>
        <label 
          onClick={() => move < 50 ? setMove(move + 50) : setMove(0)} 
          className='text-[40px] cursor-pointer'
        >
          {"+"}
        </label>
      </div>
      <div className='h-[4vh] lg:w-[15%] w-[50%] bg-[#EFEEFE] rounded-xl flex justify-center items-center'>
        <label className='text-[19px] text-center text-[#5751E1] font-semibold'>Our Testimonials</label>
      </div>
      <h1 className='lg:text-[38px] lg:text-start text-center text-[20px] font-semibold lg:w-[44%] w-full leading-snug'>
        What Students Think and Say About Coding Shaala
      </h1>
      <div className='lg:mt-[4%] w-full lg:h-[60vh] h-auto overflow-hidden'>
        <div 
          style={{ transform: `translateX(-${move}%)` }} 
          className='flex w-[200%] duration-500 flex-row'
        >
          <div className='w-[50%] flex lg:flex-row flex-col gap-10'>
            {/* Feedback 1 */}
            <div 
              className='flex flex-col gap-5 lg:w-[33%] w-full bg-[#F1FDFF] py-4 px-3 shadow-xl hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>React Frontend Course</h1>
              <div className='flex flex-row gap-2'>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
              </div>
              <p className='text-[gray] leading-6'>
                "The React Frontend Course at Coding Shaala is fantastic! The instructors are highly skilled, and the content is well-structured. I've gained practical skills that are valuable for my career."
              </p>
              <div className='flex flex-row gap-3'>
                <div className='h-[50px] w-[50px] rounded-full shadow-xl'>
                  <img className='rounded-full' src='https://example.com/author1.png' alt='Student 1'/>
                </div>
                <label className='relative top-2 text-[19px] font-semibold'>Amit Sharma</label>
              </div>
            </div>
            {/* Feedback 2 */}
            <div 
              className='flex flex-col gap-5 lg:w-[33%] w-full bg-[#EDEAFF] py-4 px-3 shadow-xl hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>FullStack MERN Course</h1>
              <div className='flex flex-row gap-2'>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
              </div>
              <p className='text-[gray] leading-6'>
                "The FullStack MERN Course was exactly what I needed to advance my career. The projects were challenging and the mentorship was excellent."
              </p>
              <div className='flex flex-row gap-3'>
                <div className='h-[50px] w-[50px] rounded-full shadow-xl'>
                  <img className='rounded-full' src='https://example.com/author2.png' alt='Student 2'/>
                </div>
                <label className='relative top-2 text-[19px] font-semibold'>Priya Verma</label>
              </div>
            </div>
            {/* Feedback 3 */}
            <div 
              className='flex flex-col gap-5 lg:w-[33%] w-full bg-[#FFF7E2] py-4 px-3 shadow-xl hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>Python Django Course</h1>
              <div className='flex flex-row gap-2'>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
              </div>
              <p className='text-[gray] leading-6'>
                "The Python Django Course was comprehensive and practical. I feel confident applying Django in real-world projects now."
              </p>
              <div className='flex flex-row gap-3'>
                <div className='h-[50px] w-[50px] rounded-full shadow-xl'>
                  <img className='rounded-full' src='https://example.com/author3.png' alt='Student 3'/>
                </div>
                <label className='relative top-2 text-[19px] font-semibold'>Ravi Kumar</label>
              </div>
            </div>
          </div>
          {/* Additional Feedback */}
          <div className='w-[50%] flex flex-row gap-10'>
            <div 
              className='flex flex-col gap-5 w-[33%] bg-[#F1FDFF] py-4 px-3 shadow-xl hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-semibold text-[19px] text-[#5751E1]'>Mobile App Development</h1>
              <div className='flex flex-row gap-2'>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
              </div>
              <p className='text-[gray] leading-6'>
                "The Mobile App Development course was engaging and informative. The hands-on approach helped me build a solid foundation."
              </p>
              <div className='flex flex-row gap-3'>
                <div className='h-[50px] w-[50px] rounded-full shadow-xl'>
                  <img className='rounded-full' src='https://example.com/author4.png' alt='Student 4'/>
                </div>
                <label className='relative top-2 text-[19px] font-semibold'>Sneha Patel</label>
              </div>
            </div>
            <div 
              className='flex flex-col gap-5 w-[33%] bg-[#EDEAFF] py-4 px-3 shadow-xl hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-bold text-[19px] text-[#5751E1]'>Next JS</h1>
              <div className='flex flex-row gap-2'>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
              </div>
              <p className='text-[gray] leading-6'>
                "The Next JS course provided a deep dive into modern web development. I appreciated the clear instructions and practical examples."
              </p>
              <div className='flex flex-row gap-3'>
                <div className='h-[50px] w-[50px] rounded-full shadow-xl'>
                  <img className='rounded-full' src='https://example.com/author5.png' alt='Student 5'/>
                </div>
                <label className='relative top-2 text-[19px] font-semibold'>Arjun Singh</label>
              </div>
            </div>
            <div 
              className='flex flex-col gap-5 w-[33%] bg-[#FFF7E2] py-4 px-3 shadow-xl hover:shadow-none duration-200 rounded-2xl'
              data-aos="fade-up"
            >
              <h1 className='font-bold text-[19px] text-[#5751E1]'>Data Analytics</h1>
              <div className='flex flex-row gap-2'>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
                <i className="fa fa-star text-[#F8BC24] text-[20px]" aria-hidden="true"></i>
              </div>
              <p className='text-[gray] leading-6'>
                "The Data Analytics course equipped me with essential skills for analyzing data. The course content was relevant and up-to-date."
              </p>
              <div className='flex flex-row gap-3'>
                <div className='h-[50px] w-[50px] rounded-full shadow-xl'>
                  <img className='rounded-full' src='https://example.com/author6.png' alt='Student 6'/>
                </div>
                <label className='relative top-2 text-[19px] font-semibold'>Meera Rao</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
