import React from "react";

function Growth() {
  return (
    <div className="pt-24 w-[100%] flex flex-row justify-center">
      <div className="w-[90%] flex lg:flex-row lg:items-center flex-col gap-10">
        <div className="lg:w-[50%] lg:hidden  w-full bg-[] flex lg:flex-row flex-col relative lg:justify-center ">
          <div className="p-2 absolute  left-[-h] top-[-4vh]  w-[30%] rounded-xl font-bold bg-[white] shadow-2xl flex flex-col items-center justify-center ">
            <label className="lg:text-[20px] text-[17px] text-center">
              We Guarantee
            </label>
            <label className="lg:text-[40px] text-[30px] text-[#1AAEF4]">
              100%
            </label>
            <label className="lg:text-[20px] text-[17px]">Placement</label>
          </div>
          <img
            className="lg:h-[65vh] h-[40vh] lg:w-[65vh] w-[40vh] rounded-full"
            src="https://www.ittrainingindore.in/wp-content/uploads/2023/06/career-with-best-it-training-indore.jpg"
          ></img>
        </div>

        <div className="lg:w-[50%] w-full flex flex-col gap-4">
          <h1 className="font-bold text-[40px]">
            Our Journey for Students Career Growth
          </h1>
          <label className="text-[17px]">
            Coding Shaala Indore is a part of a leading IT company based in
            Indore (M.P).
          </label>

          <label className=" text-[17px]">
            Embarking on your career journey is an exhilarating yet challenging
            experience. At [Your Company Name], we understand the importance of
            equipping students with the skills, resources, and guidance needed
            to navigate the competitive landscape of today's job market. Our
            "Students Career Growth" initiative is designed to empower you to
            reach your full potential and achieve your professional aspirations.
          </label>
        </div>
        <div className="lg:w-[50%] w-full bg-[] lg:flex  flex lg:flex-row flex-col relative lg:justify-center ">
          <div className="p-5 lg:flex hidden absolute  left-[5vh] w-[30%]  rounded-xl font-bold bg-[white] shadow-2xl flex-col items-center justify-center ">
            <label className="text-[20px]">We Guarantee</label>
            <label className="text-[40px] text-[#1AAEF4]">100%</label>
            <label className="text-[20px]">Placement</label>
          </div>
          <img
            className="lg:h-[65vh] lg:flex hidden h-[40vh] lg:w-[65vh] w-[40vh] rounded-full"
            src="https://www.ittrainingindore.in/wp-content/uploads/2023/06/career-with-best-it-training-indore.jpg"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Growth;
