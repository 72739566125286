import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useState } from 'react';
function Python() {
  const [activeTab, setActiveTab] = useState("overview");

    const handleTabClick = (activeTab) => {
      setActiveTab(activeTab);
    };
  return (
    <div>
    <Header/>
    <div className='w-[100%] flex lg:flex-row flex-col justify-center items-center py-[30px]'>
    <div className='w-[90%] lg:gap-[4%] gap-[30px] flex lg:flex-row flex-col'>
      <div className='lg:w-[80%] w-full gap-[12px] flex flex-col'>
      <img className='lg:h-[50vh] h-auto w-full bg-cover' src='https://www.codexfuture.in/assets/img/courses/3.png'></img>
      <div className=' w-full py-[4%] px-[4%] rounded-md bg-[#323B8D] flex justify-around gap-[10px]'>
      <button onClick={()=>handleTabClick('overview')} className='h-[5vh] w-[40%] bg-[#00E1FE] flex justify-center items-center text-[19px] font-semibold rounded-md text-[black]'>
      Overview
      </button>
      <button className=' w-[60%] font-semibold lg:text-[17px] text-[15px]' onClick={() => handleTabClick('courseContent')}>COURSE CONTENT</button>
      </div>
      {activeTab === 'overview' && <div> <label className='text-[26px] font-semibold'>Description</label>
        <p className='w-full lg:leading-10 leading-8 lg:text-[22px] text-[20px] font-serif text-justify'>Python is a computer programming language often used to build websites and software, automate tasks, and conduct data analysis. Python is a general-purpose language, meaning it can be used to create a variety of different programs and isn’t specialised for any specific problems. Python is a high-level, general-purpose programming language. Its design philosophy emphasises code readability with the use of significant indentation via the off-side rule. Python is dynamically typed and garbage-collected. It supports multiple programming paradigms, including structured (particularly procedural), object-oriented, and functional programming. It is often described as a "batteries included" language due to its comprehensive standard library. Additionally, CodeBetter IT Institute offers python internship for freshers aiming to excel in Python development.</p>
        <div className='flex flex-col gap-[10px]'>
        <label className='lg:text-[26px] text-[20px] font-semibold'>Course Content Overview:</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-     Introduction to Core Python</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-   Datatype(Tuples,Dictionary,List,Set)</label>
        <label className='lg:text-[22px] text-[20px] w-full font-sans'>-   Functions(Recursion, Lambda Expression)</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-    OOPS Concept</label>
        <label className='lg:text-[22px] text-[20px] font-sans'>-    Major Project</label>        
        </div></div>  }
        {activeTab === 'courseContent' && (
          <div className="course-content lg:px-6 px-3">
          
            <ul className='flex flex-col gap-[10px] justify-center'>
            <div className='w-full h-[5vh] bg-[#00E1FE] flex   items-center gap-3 mt-[3%]'>
            <i class="fa fa-pencil relative left-2 text-[red] text-[28px]" aria-hidden="true"></i>
              <li className=' leading-8 text-[black] text-[26px] font-sans font-semibold '>Python Programming</li></div>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>1.)   -  Introduction, Variables, Data Types, Operators, type Conversion</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>2.)   -  Control structure - If else, switch, ternary operator, For loop, nested for loop, while loop, break, continue</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>3.)   -   Collection - List, Dictionary, Set</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>4.)  -  "functions, argument, return type, Arbitrary Arguments, *args, Keyword Arguments</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>5.)  -  "kwargs, Default value parameters, nested functions. Anonymous functions. Local vs global variables"</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>6.)   -   Introduction to OOP , Object , Class, Methods, initializer, self, static, List of object</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Inheritance - single, multilevel, hierarchical , multiple, pass keyword</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Runtime Polymorphism, Method overriding, super keyword, Abstraction, Abstract Class, ABC Module</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Modules and Access specifiers, Exception Handling try, except, raise, else, finally, custom exception</li>
              <li className=' leading-8 text-[white] text-[20px] font-sans '>7.)  -   Enumeration, File Handling, CSV read, write</li>
              
            </ul>
           
          </div>

          
          
          
          
        )}
     
      </div>

      <div className='  lg:w-[50%] w-full items-center lg:h-[65vh] h-auto flex flex-col border lg:gap-[2.5%] gap-[8px]'>
      <h1 className='text-[black] font-semibold lg:text-[35px] m-0 text-center'>Course <span className=' text-center text-[#323B8D] lg:text-[35px] font-semibold'>Features</span></h1>
      <div className='w-[100%] flex pt-4'>
      <div className='w-[50%] flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Group%20878.png'></img>
      <label className='lg:text-[20px] font-semibold'>Duration</label>
      </div>
      <div className='  w-[50%] flex justify-end  font-semibold lg:text-[20px] pr-[6%]'>1 to 2 months</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%]  flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-language.png'></img>
      <label className='lg:text-[20px] font-semibold'>Language</label>
      </div>
      <div className='  w-[40%]  flex justify-end pr-[6%] font-semibold lg:text-[20px]'>Hindi/English</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex'>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-certificate.png'></img>
      <label className='lg:text-[20px] font-semibold'>	Certificate</label>
      </div>
      <div className=' pr-[6%] flex w-[40%] justify-end items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 '>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-md-people.png'></img>
      <label className='lg:text-[20px] font-semibold'>Expert trainers</label>
      </div>
      <div className=' flex pr-[6%] w-[40%] justify-end items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[90%] pl-[6%]  flex  gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-ios-time.png'></img>
      <label className='lg:text-[20px] font-semibold'>Flexible Timing</label>
      </div>
      <div className=' flex pr-[6%] w-[50%] justify-end  bg-[] items-center font-semibold lg:text-[20px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 bg-[]'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20payment-cash.png'></img>
      <label className='lg:text-[20px] font-semibold'>	Fee Installements	</label>
      </div>
      <div className=' pr-[6%] flex justify-end w-[40%]  items-center font-semibold lg:text-[20px] '>Yes</div>
      
      </div>
      <hr></hr>
     
       <button className='w-[50%] rounded-md py-2 px-2 bg-[#00E1FE] lg:text-[22px] text-[black] font-semibold mt-[5%]'>Reach Us</button>
      </div>

    
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Python