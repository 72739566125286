import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
function Nextjs() {
  return (
    <div>
    <Header/>
    <div className='w-[100%] flex lg:flex-row flex-col justify-center items-center py-[30px]'>
    <div className='w-[90%] lg:gap-[4%] gap-[30px] flex lg:flex-row flex-col'>
      <div className='lg:w-[80%] w-full gap-[12px] flex flex-col'>
      <img className='lg:h-[50vh] h-auto w-full bg-cover' src='https://miro.medium.com/v2/resize:fit:1000/1*v3XndYeIsBtk4CkpMf7vmA.jpeg'></img>
      <div className=' w-full py-[4%] px-[4%] rounded-md bg-[#323B8D] flex justify-around gap-[10px]'>
      <button className='h-[5vh] w-[40%] bg-[#00E1FE]  text-[19px] font-semibold rounded-md text-[black]'>
      Overview
      </button>
      </div>
      <label className='text-[26px] font-semibold'>Description</label>
      <p className='w-full lg:leading-10 leading-8 lg:text-[25px] text-[20px] font-serif text-justify'>Next.js is a popular React framework that provides a robust solution for building server-side rendered (SSR) and static websites with React. It offers features like automatic code splitting, client-side routing, and server-side rendering out of the box, making it a powerful tool for building scalable and high-performance web applications.</p>
      <div className='flex flex-col gap-[10px]'>
      <label className='lg:text-[26px] text-[20px] font-semibold'>Next.js Routing</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>- API Routes</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>- Datatype(Tuples,Dictionary,List,Set)</label>
    <label className='lg:text-[24px] text-[20px] w-full font-sans'>- CSS and Styling in Next.js</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>- Middleware and Authentication</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>-  Deployment</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>-  Performance Optimization</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>-  Internationalization (i18n):</label>
    <label className='lg:text-[24px] text-[20px] font-sans'>- Environment Variables and Configurations</label>
      </div>
      </div>

      <div className='  lg:w-[50%] w-full items-center lg:h-[65vh] h-auto flex flex-col border lg:gap-[2.5%] gap-[8px]'>
      <h1 className='text-[black] font-semibold lg:text-[35px] m-0 text-center'>Course <span className=' text-center text-[#323B8D] lg:text-[35px] font-semibold'>Features</span></h1>
      <div className='w-[100%] flex pt-4'>
      <div className='w-[50%] flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Group%20878.png'></img>
      <label className='lg:text-[22px] font-semibold'>Duration</label>
      </div>
      <div className='  w-[50%] flex justify-end  font-semibold lg:text-[22px] pr-[6%]'>1 to 2 months</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%]  flex pl-[6%]  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-language.png'></img>
      <label className='lg:text-[22px] font-semibold'>Language</label>
      </div>
      <div className='  w-[40%]  flex justify-end pr-[6%] font-semibold lg:text-[22px]'>Hindi/English</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex'>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20awesome-certificate.png'></img>
      <label className='lg:text-[22px] font-semibold'>	Certificate</label>
      </div>
      <div className=' pr-[6%] flex w-[40%] justify-end items-center font-semibold lg:text-[22px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 '>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-md-people.png'></img>
      <label className='lg:text-[22px] font-semibold'>Expert trainers</label>
      </div>
      <div className=' flex pr-[6%] w-[40%] justify-end items-center font-semibold lg:text-[22px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[90%] pl-[6%]  flex  gap-4'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20ionic-ios-time.png'></img>
      <label className='lg:text-[22px] font-semibold'>Flexible Timing</label>
      </div>
      <div className=' flex pr-[6%] w-[50%] justify-end  bg-[] items-center font-semibold lg:text-[22px]'>Yes</div>
      
      </div>
      <hr></hr>
      <div className='w-[100%] flex '>
      <div className='w-[60%] pl-[6%] flex  items-center gap-4 bg-[]'>
      <img className='lg:h-[3vh] h-[2vh]' src='https://codebetter.in/images/icons/Icon%20payment-cash.png'></img>
      <label className='lg:text-[22px] font-semibold'>	Fee Installements	</label>
      </div>
      <div className=' pr-[6%] flex justify-end w-[40%]  items-center font-semibold lg:text-[22px] '>Yes</div>
      
      </div>
      <hr></hr>
     
       <button className='w-[50%] rounded-md py-2 px-2 bg-[#00E1FE] lg:text-[22px] text-[black] font-semibold mt-[5%]'>Reach Us</button>
      </div>

    
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Nextjs