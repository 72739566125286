import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../Components/Header';
import image2 from '../images/About-Us-Coaching-Class-Institute.jpg';
import Opportunity from '../Components/Opportunity';
import Growth from '../Components/Growth';
import Beliefs from '../Components/Beliefs';
import Mission from '../Components/Mission';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';

function Aboutus() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const navigate = useNavigate()

  return (
    <div>
      <Header />
      <div className='lg:h-[35vh] h-[20vh] relative w-full flex flex-col items-center justify-center'>
        <img className='h-full w-full brightness-50' src={image2} alt="About Us" />
        <div className='absolute w-[80%] flex flex-col items-center'>
          <h1 className='lg:text-[50px] text-[30px] font-bold text-white' data-aos="fade-up">
            About Us
          </h1>
          <div className='flex flex-row gap-3' data-aos="fade-up">
            <label className='text-[18px] font-semibold text-white cursor-pointer' onClick={() => navigate('/')}>Home</label>
            <label className='lg:text-[18px] text-[16px] font-semibold text-white'>{'>'}</label>
            <label className='lg:text-[18px] text-[16px] font-semibold text-white'>About Us</label>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <Opportunity />
      </div>
      <div data-aos="fade-up">
        <Growth />
      </div>
      <div data-aos="fade-up">
        <Beliefs />
      </div>
      <div data-aos="fade-up">
        <Mission />
      </div>
      <Footer />
    </div>
  );
}

export default Aboutus;
