import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Whylearnwithus() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className='w-full py-12 bg-gray-800 flex flex-col items-center gap-6'>
      <button className='h-12 lg:w-1/6 w-1/2 bg-gray-700 rounded-xl text-white font-sans font-semibold' data-aos="fade-up">
        Why learn With Us?
      </button>
      <label className='text-3xl font-bold text-white text-center' data-aos="fade-up">
        Start your Learning Journey Today!
      </label>
      <label className='text-gray-400 lg:w-1/2 w-full text-lg font-sans text-center leading-8' data-aos="fade-up">
        With 12+ years of experience in IT industry, we have started BEST IT Academy to provide both Offline/Online corporate level training.
      </label>
      <div className='flex lg:flex-row flex-col gap-6 w-full mt-8'>
        <div className='flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6' data-aos="fade-up">
          <img className='h-24 w-24' src='https://www.shutterstock.com/image-vector/business-expert-team-icon-executive-260nw-1833260176.jpg' alt="Learn with Experts" />
          <h1 className='font-semibold text-2xl text-white font-sans mt-2'>Learn with Experts</h1>
          <label className='text-gray-400 text-center w-4/5 text-lg font-sans'>
            Learn from highly skilled industry experts with our dynamically customized curriculum.
          </label>
        </div>
        <div className='flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6' data-aos="fade-up">
          <img className='h-24 w-24' src='https://media.istockphoto.com/id/1317086782/vector/laptop-screen-with-play-icon-in-flat-style-play-sign.jpg?s=612x612&w=0&k=20&c=55hib6vmm2cmV3PQ3wDv12KPvoQR5AQvwO5U43iaics=' alt="Offline and Online Trainings" />
          <h1 className='font-semibold text-2xl text-white font-sans mt-2'>Offline and Online Trainings</h1>
          <label className='text-gray-400 text-center w-4/5 text-lg font-sans'>
            Our both training modes provide learning opportunity for non-working candidates and students.
          </label>
        </div>
        <div className='flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6' data-aos="fade-up">
          <img className='h-24 w-24' src='https://e7.pngegg.com/pngimages/774/364/png-clipart-computer-icons-academic-certificate-flat-design-diploma-miscellaneous-text-thumbnail.png' alt="100% Job Assistance" />
          <h1 className='font-semibold text-2xl text-white font-sans mt-2'>100% Job Assistance</h1>
          <label className='text-gray-400 text-center w-4/5 text-lg font-sans'>
            Our customized curriculum which is designed dynamically as per current market requirements.
          </label>
        </div>
        <div className='flex flex-col gap-4 lg:w-1/4 w-full justify-center items-center lg:mt-0 mt-6' data-aos="fade-up">
          <img className='h-24 w-24' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjfB2-PUXAmxNfukEDFM1U_0jCJITKnlh1lJLAw4wOMzcyxLoCX6XwDOxCvVl5Tu7X8_M&usqp=CAU' alt="Capstone Projects" />
          <h1 className='font-semibold text-2xl text-white font-sans mt-2'>Capstone Projects</h1>
          <label className='text-gray-400 text-center w-4/5 text-lg font-sans'>
            Provides opportunity to work on industry-based internships with personalized trainer guidance.
          </label>
        </div>
      </div>
    </div>
  );
}

export default Whylearnwithus;
