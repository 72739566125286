import React from 'react'
import Header from '../Components/Header';
import Webdev from "../Components/Webdev";
import Footer from '../Components/Footer';
function Course() {
  return (
    <div className='w-[100%] flex justify-center items-center flex-col'>
    <Header/>
    <div className='w-[90%] mt-14'>
    <Webdev/>
    </div>
    <Footer/>
    </div>
  )
}

export default Course