import React from 'react'

function Mission() {
  return (
    <div className='py-24 w-[100%] flex flex-row justify-center'>
    <div className='w-[90%] flex lg:flex-row flex-col gap-10'>
    <div className='lg:w-[50%] w-full'>
    <img className='w-full lg:h-[62vh] h-[30vh] bg-cover' src='https://www.ittrainingindore.in/wp-content/uploads/2023/06/best-it-training-team-coaching-class.jpg'></img>
    </div>
    <div className='lg:w-[50%] w-full flex flex-col gap-6 bg-[]'>
    <h1 className='font-bold lg:text-[40px] text-[28px]'>Our Mission, Vision and Values</h1>
    <div className='flex flex-col gap-8'>
     <div className='flex lg:flex-row flex-col gap-6 w-full'>
       <div className='h-[45px] lg:w-[6%] w-[15%] bg-[#D9F3FF] rounded-lg flex justify-center items-center'>
       <img className='h-[25px]' src='https://www.ittrainingindore.in/wp-content/uploads/2023/03/mission.svg'></img>
       </div>
       <div className='flex flex-col gap-2 w-[92%] bg-[]'>
       <label className='text-[17px] font-semibold'>Mission</label>
       <label className='text-[17px] '>Our aim is to provide skilled manpower in the areas of Web design , Web development, Software Development, Graphic Design, and SEO along with introducing students and professionals with the latest technology in the IT industry.</label>
       </div>
       
     </div>
     <div className='flex lg:flex-row flex-col gap-6 w-full'>
     <div className='h-[45px] lg:w-[6%] w-[15%] bg-[#E3FFE0] rounded-lg flex justify-center items-center'>
     <img className='h-[25px]' src='https://www.ittrainingindore.in/wp-content/uploads/2023/03/vision.svg'></img>
     </div>
     <div className='flex flex-col gap-2 w-[92%] bg-[]'>
     <label className='text-[17px] font-semibold'>Vision</label>
     <label className='text-[17px]'>
     To deliver best output in the form of projects and courses, that will show the students clear vision for their future success.</label>
     </div>
     
   </div>
   <div className='flex lg:flex-row flex-col gap-6 w-full'>
   <div className='h-[45px] lg:w-[6%] w-[15%] bg-[#ECE3FF] rounded-lg flex justify-center items-center'>
   <img className='h-[25px]' src='https://www.ittrainingindore.in/wp-content/uploads/2023/03/values.svg'></img>
   </div>
   <div className='flex flex-col gap-2 w-[92%] bg-[]'>
   <label className='text-[17px] font-semibold'>Mission</label>
   <label className='text-[17px]'>To always have transparency with everyone who connects with us with loyalty and integrity.</label>
   </div>
   
 </div>
 </div>
         
       
    </div>
    
    </div>
    </div>
  )
}

export default Mission